.av-delete-tenant {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 6;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  &-enter {
    opacity: 0;

    &-active {
      animation: fadeIn 0.5s forwards;
    }
  }

  &-exit {
    opacity: 1;

    &-active {
      animation: fadeOut 0.5s forwards;
    }
  }

  &__close {
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 4;
    cursor: pointer;

    i {
      font-size: 1.5em;
    }
  }

  &__content {
    margin: auto;
    width: 100%;
    max-width: 70em;
    overflow-y: auto;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    color: #728393;
    border-radius: 5px;

    &-top {
      padding: 4em 0 0;
      width: 90%;
      margin: 0 auto;
      &_container {
        margin-bottom: 3em;

        h3 {
          color: #d61a19;
          font-weight: 800;
          font-size: 2em;
          margin: 0 0 0.5em;
          text-transform: capitalize;
          display: flex;
          align-items: center;
          gap: 0.5em;
        }
        &-icon {
          font-size: 1.5em;
        }

        p {
          color: #272727;
          margin: 0;
          line-height: 1.2rem;
          letter-spacing: 0.5px;
          margin-bottom: 2em;

          i {
            font-weight: 700;
          }
        }
      }
    }

    &-bottom {
      margin-top: 2em;
      box-shadow: 0em 0em 2em 0em rgba(0, 0, 0, 0.25);
      height: 100px;
      background: white;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 3em;

      button {
        outline: none;
        border: none;
        padding: 14px 20px;
        font-weight: 550;
        font-size: 1em;
        border-radius: 5px;

        text-align: center;
        cursor: pointer;
        transition: all 300ms ease;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        background: #d61a19;
        color: #ffffff;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        gap: 1em;

        &:hover {
          border: 1px solid #d61a19;
          color: #d61a19;
          background: white;
        }
      }
    }
  }
}
