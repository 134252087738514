.av-login-page {
    width: 100%;
    min-height: 100%;
    background-color: #F7F8FA;
    padding-top: 5%;
    font-size: 13px;

    &__form {
        width: 100%;
        overflow: hidden;
        padding: 3em;
        font-size: 1em;
        margin: 0 auto;
        animation: backInRight 1s forwards;
        transition-property: border-color, background-color, box-shadow, opacity, filter, transform;
        transition-duration: 300ms;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

        img {
            width: 50%;
            object-fit: contain;
            margin-bottom: 32px;
        }

        h1 {
            font-size: 2em;
            font-weight: bold;
            color: rgb(20, 49, 75);
            margin: 0;
        }

        span {
            font-family: inherit;
            font-size: 0.85em;
            font-style: normal;
            font-weight: inherit;
            line-height: inherit;
            letter-spacing: 0em;
            text-transform: none;
            color: #728393;
        }

        &__input-container {
            display: flex;
            flex-direction: column;
            gap: 16px;
            align-items: stretch;
            margin: 32px 0;
        }

        &__extras {
            margin-top: 16px;

            span {
                a {
                    color: inherit;
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .av-login-page {
        &__form {
            max-width: 500px;
            border: 0;
            border-radius: 1em;
            box-shadow: 0px 0.5em 2em 0px rgba(0, 0, 0, 0.2);
        }
    }
}