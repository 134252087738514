@import "../../../../variables.scss";

.av-tenants-grid {
    width: 88%;
    margin: 0 auto;
    margin-top: 32px;

    .x-row {
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        align-content: stretch;
        flex-wrap: wrap;
        font-size: 1em;
        // gap: 20px;

        .x-col {
            width: calc(100% / 3);
            padding: 8px;

            .container {
                width: 100%;
                border: 1px solid transparent;
                box-shadow: 0px 0.5em 2em 0px rgba(0, 0, 0, 0.05);
                overflow: hidden;
                background-color: white;
                transition-duration: 500ms;
                animation-timing-function: $transition-function;
                transition-timing-function: $transition-function;
                transition-property: all;
                border-radius: 12px;

                &:hover {
                    border-color: rgb(15, 113, 211);
                    box-shadow: 0px 0.5em 2em 0px rgba(0, 0, 0, 0.3);
                }

                &__logo {
                    min-height: 250px;
                    justify-content: center;
                    flex-direction: column;
                    display: flex;
                    align-items: center;
                    position: relative;

                    span.img {
                        display: inline-block;
                        vertical-align: middle;

                        img {
                            display: block;
                            max-width: 100%;
                            height: auto;
                            width: 145px;
                            aspect-ratio: auto 145 / 110;
                            overflow-clip-margin: content-box;
                            overflow: clip;
                        }
                    }

                    div.settings {
                        color: #fff;
                        font-weight: 800;
                        position: absolute;
                        top: 5px;
                        font-size: .8em;
                        left: 5px;
                        padding: 10px;
                        cursor: pointer;
                        transition-timing-function: $transition-function;

                        &:hover {
                            opacity: .8;
                        }
                    }
                }

                &__content {
                    padding: 3em;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    font-size: 1em;

                    .title {
                        margin-bottom: 1em;
                        font-size: 1.25em;
                        color: rgb(20, 49, 75);
                        font-weight: bold;
                        line-height: 1.4;
                    }

                    .info {
                        margin-bottom: 4em;
                        color: #728393;
                        font-size: 0.85em;
                    }

                    .actions {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        gap: 8px;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        align-items: center;

                        button {
                            width: fit-content;
                        }
                    }
                }
            }

            @media(max-width: 800px) {
                width: calc(100% / 2);
            }

            @media(max-width: 500px) {
                width: 100%;
            }
        }
    }
}