@import "../../../../variables.scss";

.av-tenant-list {
  display: flex;
  flex-direction: column;
  gap: calc(1.6em / 2);

  &__card {
    width: 100%;
    padding: 1em;
    border-radius: 0.8em;
    font-size: 0.85em;
    line-height: 1.4;

    .x-row-inner {
      display: flex;
      flex-wrap: wrap;
      // gap: 8px;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: auto;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      align-content: center;

      .x-col {
        flex-grow: 0;
        flex-shrink: 1;
        width: auto;
        max-width: 100%;
        // flex: 1;
        margin: calc(20px / 2);

        &:first-child {
          flex-basis: calc(20% - clamp(0px, 20px, 9999px));
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          flex-basis: calc(15% - clamp(0px, 20px, 9999px));
        }

        word-wrap: break-word;
      }

      .actions {
        // flex-basis: calc(35% - clamp(0px, 20px, 9999px));
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 8px;
        flex-wrap: wrap;
        justify-content: flex-end;
      }
    }
  }

  &__header {
    background-color: #1789fb1a;
    color: #1789fb;
    font-weight: 800;
    font-size: 0.95em;
  }

  &__item {
    border: 1px solid #dfe3e7;
    color: rgb(20, 49, 75);
  }

  .av-custom-button {
    border-radius: 0.35em;
    width: fit-content;
  }
}

@media (max-width: $breakpoint-tablet) {
  .av-tenant-table {
    &__card {
      .x-row-inner {
        .x-col {
          &:first-child {
            flex-basis: calc(50% - clamp(0px, 20px, 9999px));
          }

          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            flex-basis: calc(50% - clamp(0px, 20px, 9999px));
          }
        }
      }
    }
  }
}

@media (max-width: $breakpoint-phone) {
  .av-tenant-table {
    &__card {
      .x-row-inner {
        .x-col {
          &:first-child {
            flex-basis: calc(100% - clamp(0px, 20px, 9999px));
          }

          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            flex-basis: calc(100% - clamp(0px, 20px, 9999px));
          }
        }
      }
    }
  }
}
