.av-custom-button {
  outline: none;
  border: none;
  padding: 16px;
  font-weight: 600;
  font-size: 1em;
  border-radius: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: start;
  cursor: pointer;
  transition: all 300ms ease;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  &:disabled {
    pointer-events: none;
  }
  main {
    display: flex;
    align-items: center;
    gap: 1em;
    margin: 0 auto;
    // width: 5%;
  }
  &-primary {
    color: white;
    background-color: #1789fb;

    &:hover {
      background-color: rgb(15, 113, 211);
      color: #8ed1fc;
    }
  }

  &-secondary {
    background-color: #dfe3e7;
    color: #728392;

    &:hover {
      color: #a3acb5;
    }
  }
}
