.av-custom-select {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  min-height: 50px;
  border: 1px;
  overflow: hidden;
  border: 1px solid #dfe3e7;
  border-radius: 3px;
  font-size: 13px;
  color: #728393;
  margin-bottom: 0.5em;
  transition: all 200ms ease-in-out;

  label {
    display: block;
    width: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 12px;
    font-size: 1em;
    align-items: center;
    align-content: center;
    min-height: 35px;
    background-color: #dfe3e7;
    transition: all 200ms ease-in-out;
  }

  select {
    background-color: transparent;
    flex: 1;
    font-size: 1.2em;
    border: none;
    outline: none;
    color: #728393;
    caret-color: #728393;
    line-height: 120%;
    padding: 12px;
  }
}
