@import "../../variables.scss";

.av-tenants-page {
    min-height: 100%;
    width: 100%;
    padding: 64px 0;
    font-size: 15px;

    &__table-container {
        width: 88%;
        margin: 0 auto;
        max-width: 1400px;
        background-color: #ffffff;
        border-radius: 3px;
        padding: 2em 3em;
        box-shadow: 0px 0.5em 2em 0px rgba(0, 0, 0, 0.1);

        .controls {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            gap: 0.5em;
            flex-wrap: wrap;
            justify-content: space-between;

            &__item {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                gap: 0.5em;

                &:nth-child(1) {
                    font-size: 1em;
                    line-height: 1.2em;

                    span {
                        display: block;

                        &:first-child {
                            font-size: 1.4em;
                            cursor: pointer;
                            transition-duration: 300ms;
                            transition-timing-function: $transition-function;

                            &:hover {
                                color: #168AFB;
                            }
                        }

                        &:last-child {
                            color: #13314B;
                            font-weight: 800;
                        }
                    }
                }

                &:nth-child(2) {
                    .grid_list_toggle {
                        font-size: 1.2em;
                        cursor: pointer;
                        transition-timing-function: $transition-function;
                        transition-duration: 300ms;
                        margin-right: 16px;

                        &:hover {
                            color: #168AFB;
                        }
                    }

                    .av-search-input {
                        width: 200px;
                    }

                    .av-custom-button {
                        border-radius: 0.35em;
                        font-weight: 500;
                        width: fit-content;
                    }
                }
            }
        }

        .spacer {
            width: 100%;
            height: 1px;
            background-color: #DFE3E7;
            margin-top: 1em;
            margin-bottom: 2em;
        }
    }
}