@import "../../../variables.scss";

.av-dashboard-drawer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-width: 200px;
  padding-top: 64px;
  border: 0;
  background-color: rgb(20, 49, 75);
  box-shadow: 0em 0em 2em 0em rgba(0, 0, 0, 0.25);
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.400, 0.000, 0.200, 1.000);
  position: relative;

  &__close {
    position: absolute;
    top: 4px;
    left: 4px;
    transition-duration: 500ms;
    transition-timing-function: cubic-bezier(0.400, 0.000, 0.200, 1.000);
    cursor: pointer;

    &:hover {
      color: white;
    }

    i {
      font-size: 24px;
    }
    
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    width: 100%;
 }

  &__nav-items {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    width: 100%;
  }

  .spacer {
    width: 100%;
    height: 1px;
    margin: 30px 0;
    background-color: #1E4262;
  }
}


.av-drawer-user,
.av-nav-item {
  cursor: pointer;
  font-size: 13px;
  color: #fff;
  font-weight: 600;
  padding: 0.75em 1.5em;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.400, 0.000, 0.200, 1.000);

  &-active,
  &:hover {
    background-color: #1789FB;
  }

  .icon {
    font-size: 18px;
  }

  .title {
    display: inline-block;
    margin-left: 8px;
    text-transform: capitalize;
  }
}

.av-drawer-user {
  padding: 1.75em 1.5em;

  &:hover {
    background-color: rgba(255,255,255,0.2);
  }

  .img {
    width: 1.5em;
    height: 1.5em;
    background-color: #F2B02A;
    border-radius: 50%;
  }

  .title {
    font-size: 1.2em;
  }
}

@media (min-width: $breakpoint-laptop) {
  .av-dashboard-drawer{
    box-shadow: none;

    &__close {
      display: none;
    }
  }
}