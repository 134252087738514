.skeleton-loader {
  display: flex;
  flex-direction: column;
  gap: calc(1.6em / 2);

  &__card {
    width: 100%;
    padding: 1.5em 1em;
    border-radius: 0.8em;
    font-size: 0.85em;
    line-height: 1.4;

    .x-row-inner {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: auto;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      align-content: center;

      .x-col {
        flex: 1;
        word-wrap: break-word;
      }
    }
  }

  &__header {
    background-color: #1789fb1a;
    color: #1789fb;
    font-weight: 800;
    margin-bottom: 2em;
    font-size: 15px;
  }
}

.tableSkeletonrow {
  display: grid;
  grid-template-columns: 1fr 2fr 3fr 1fr 1fr;
  grid-template-rows: 100px;
  gap: 10px;
}

.skeleton {
  background: #e1e1e1;
  border-radius: 4px;
  height: 50px;
  position: relative;
  overflow: hidden;
}

.skeleton::before {
  content: "";
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(
    to right,
    transparent 0%,
    #e8e8e8 50%,
    transparent 100%
  );
  animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}
