@import "../../../variables.scss";

.av-search-input {
    position: relative;
    width: 100%;

    input {
        width: 100%;
        font-size: 1em;
        outline: none;
        border: none;
        border: 1px solid #DFE3E7;
        border-radius: .5em;
        padding: 12px 32px;
        color: #000;
    }

    .search {
        cursor: pointer;
        font-size: 1.2em;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
    }

    .cancel {
        cursor: pointer;
        font-size: 1.5em;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        color: rgba(0, 0, 0, 0.25);
        transition-timing-function: $transition-function;
        transition-duration: 300ms;

        &:hover {
            color: rgba(0, 0, 0, 0.3);
        }
    }
}