.av-add-tenant {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 2;
  font-size: 14px;

  &-enter {
    opacity: 0;

    &-active {
      animation: fadeIn 0.5s forwards;
    }
  }

  &-exit {
    opacity: 1;

    &-active {
      animation: fadeOut 0.5s forwards;
    }
  }

  &__close {
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 4;
    cursor: pointer;

    i {
      font-size: 1.5em;
    }
  }

  &__content {
    margin-left: auto;
    padding: calc(1em * 2);
    width: 100%;
    max-width: 40em;
    overflow-y: auto;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    box-shadow: 0em 0em 2em 0em rgba(0, 0, 0, 0.25);
    color: #728393;

    form {
      display: flex;
      flex-direction: column;
      gap: 1em;

      .color-menu-wrapper {
        display: flex;
        align-content: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        transition: all 0.3s ease-in;

        &__cro {
          position: relative;
          width: 100%;
          height: 100%;
          cursor: pointer;

          .popup {
            position: absolute;
            box-sizing: border-box;
            overflow-y: auto;
            left: 0%;
            z-index: 999;
            background: white;
            box-shadow: 0 0 2px 2px rgb(224, 221, 221);
          }
        }
      }
    }

    h3 {
      color: #1789fb;
      font-weight: bold;
      font-size: 1.3em;
      margin-bottom: 1em;
    }

    .spacer {
      height: 1px;
      width: 100%;
      background-color: #dfe3e7;
      margin-top: 1em;
      margin-bottom: 2em;
    }
    .btn-container-div {
      margin-top: 1em;
      width: 100%;

      .av-update-tenant-delete-button {
        color: red;
        border: none;
        outline: none;
        padding: 1em;
        background: none;
        width: 100%;
        margin-top: 1em;
        font-size: 15px;
        text-decoration: underline;
        text-underline-offset: 3px;
        font-weight: 800;
        cursor: pointer;
        transition: all 300ms ease-in-out;

        &:hover {
        font-size: 16px;
        }
      }
    }
  }
}
